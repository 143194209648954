<script setup>
import { onMounted, ref } from 'vue';

const model = defineModel({
    type: String,
    required: true,
});

const props = defineProps({
    codeClass: {
        type: String,
        default: 'flex justify-center items-center px-3 text-white pointer-events-none dark:text-gray-400 text-left bg-black rounded-l-lg',
        required: false,
    },
    inputClass: {
        type: String,
        default: 'text-left dir-ltr rounded-l-none text-black border-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 rounded-lg block w-full dark:placeholder-gray-400',
        required: false,
    }
})
const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <div class="flex dir-ltr">
        <div :class="props.codeClass">
            <span>+966</span>
        </div>
        <input 
            v-model="model" 
            ref="input" 
            type="tel" 
            pattern="\d*" 
            maxlength="9" 
            :class="props.inputClass"
            placeholder="5xxxxxxxx">
    </div>    
</template>
